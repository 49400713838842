<!-- 组件1   单商品 -->
<template>
     <div class="ThemeFour">
       <div class="mainBox"  :style="{background:ItemDate.topicColor}">
         <!--  :style="{backgroundImage:'url('+ItemDate.pictureAndLinkVOS[0].pic+')'}" -->
          <div class="conBox"  >
              <div class="leftBox" @click="jumpDetail(item.productVOS[0].id,item.productVOS[0].isRecentExpiration)">
                 <!-- :src="item.productVOS[0].albumPics.split(',')[0]| defaultImage" -->
                  <img :alt="item.productVOS[0].name | defaultName" :src="item.pic | defaultImage">
              </div>
              <div class="rightBox">
                  <h3 class="name" :title="item.productVOS[0].name">{{item.productVOS[0].name}}</h3>
                  <div class="special">规格：{{item.productVOS[0].specification}} {{item.productVOS[0].partSpecification}}</div>
                  <div class="special">效期优于：{{ item.productVOS[0].validDateFromat }}</div>
                  <div class="bottom">
                    <div class="price">￥{{item.productVOS[0].price==="***"?"会员可见":item.productVOS[0].price}}</div>
                    <div class="buy" @click="addCartOneFun(item.productVOS[0])">去购买
                    </div>
                  </div> 
              </div>
              
              <div class="clear"></div>
          </div>
       </div>
    </div>
</template>
<script>
import { AddCart } from "api/product.js";
import { mapMutations, mapState } from "vuex";
  export default {
    name: "ThemeFour",
    data() {
      return {
        item:''
      }
    },
    props: {
        // 滚动数组
        ItemDate: {
            type: Object
        },
    },
    created() {
       console.log('ThemeFour',this.ItemDate)
       this.item=this.ItemDate.pictureAndLinkVOS[0]
    },
        computed: {
    ...mapState({
      isqualification: (state) => state.isqualification,
    }),
  },
    methods: {
           ...mapMutations({
      setCarGoodsNum: "setCarGoodsNum",
    }),
      // 点击跳转详情页
      jumpDetail(id, isRecentExpiration) {
         this.$router.push({ name: "ProductDetail", query: { id: id ,isRecentExpiration:isRecentExpiration } });
      },
      // 点击加入购物车
      addCartOneFun(data) {
        if(data.price==="***"){
          this.$message({
              message:'请点击顶部右侧【手机商城】，扫码二维码下载商城App，上传认证信息',
              type:"warning"
          })
          return false
      }
      
      console.log('datasss',data)
                    let memberStoreId=localStorage.getItem("memberStoreId")
                      const addcartDate = [];
                      addcartDate.push({
                        isRecentExpiration:data.isRecentExpiration,
                        quantity: data.minOrderNum,
                        productId:data.id==-1?data.productId:data.id,
                        goodNum:data.goodNum,
                        memberStoreId:memberStoreId
                        // memberStoreId:this.StoreList[0].id
                      });

                        this.num=data.minOrderNum
                      // 加入购物车接口
                      AddCart(addcartDate).then((data) => {
                        // this.$router.push({ name: "Cart", query: { id: id } });
                        if (data.data.code == 200) {
                          // 提示信息
                          this.$notify({
                            title: "加入购物车",
                            message: "成功",
                            type: "success",
                            duration: 1000,
                          });
                          this.setCarGoodsNum(this.num);
                        } else {
                          this.$notify({
                            title: "提示信息",
                            message: data.data.msg,
                            type: "error",
                            duration: 1000,
                          });
                        }
                      })

       
      },
    },
     //   注册方法
    mounted() {

    }
  }
</script>
<style lang="scss" scoped>
.ThemeFour {
    width:100%;
    display: block;
    height: auto;
    .mainBox{ 
        padding:0.08rem;
         .conBox {
            width:calc(100% - 20px);
              margin-right: 10px;
            margin-left:10px;
            margin-bottom: 10px;
            box-sizing: content-box;
            background-repeat: no-repeat;
            background-size: 100%;
            height: 100%;
            background: #fff;
            border-radius: 10px;
            .leftBox{
              width:45%;
              height:150px;
              // width:calc(40% - 0.16rem);
              float: left;
              padding:10px;
              img{
                margin: 0 auto;
                width:auto;
                height: 100%;
                cursor: pointer;

              }
            }
            .rightBox{
               width:calc(55% - 80px);
               float: left;
              display: block;
              padding:20px;
              
              .name {
                padding:10px 0;
                color: #333;
                font-size:18px;
                line-height:30px;
                font-weight: bold;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                text-align: left;
              }
                .special {
                  color:  #525252;
                  font-size:14px;
                  line-height:20px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  text-align: left;
                }

                .bottom {
                  margin-top: 10px;
                  width: 100%;
                  font-size:14px;
                  font-weight: bold;
                  display: flex;
                    height:30px;
                  justify-content: space-between;
                  align-items: center;
                }

                .price {
                  color: #f43c38;
                   font-size:14px;
                  font-weight: bold;
                   line-height: 26px;
                }

                .buy {
                  background: #FFD303;
                  color: #000;
                  font-size:14px;
                  font-weight: bold;
                  border-radius:10px;
                    height: 20px;
                  line-height: 20px;
                  padding:5px 10px;
                }


            }
            .clear{
              clear: both;
            }
          }
    }
     
  }
</style>