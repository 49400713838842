<!-- 组件2   双商品 -->
<template>
     <div class="ThemeFive">
          <div class="mainBox" :style="{background:ItemDate.topicColor}">
            <div class="wrapbox"  v-for="(item, index) in ListArr" :key="index">
            <!--  :style="{backgroundImage:'url('+item.pic+')'}" -->
              <div class="conBox"  >
                  <div class="top" @click="jumpDetail(item.productVOS[0].id,item.productVOS[0].isRecentExpiration)">
                    <img :alt="item.productVOS[0].goodsName | defaultName" :src="item.pic | defaultImage" >
                    <!-- :src="item.productVOS[0].albumPics.split(',')[0] | defaultImage" -->
                  </div>
                  <h3 class="name" :title="item.productVOS[0].name">{{item.productVOS[0].name}}</h3>
                  <div class="special">规格：{{item.productVOS[0].specification}} {{item.productVOS[0].partSpecification}}</div>
                  <div class="special">效期优于：{{ item.productVOS[0].validDateFromat }}</div>
                  <div class="bottom">
                    <div class="price">￥{{item.productVOS[0].price==="***"?"会员可见":item.productVOS[0].price}}</div>
                    <div class="buy" @click="addCartOneFun(item.productVOS[0])">去购买
                    </div>
                  </div> 
              </div>
            </div>
              <div class="clear"></div>

        </div>
    </div>
</template>
<script>
import { AddCart } from "api/product.js";
import { mapMutations, mapState } from "vuex";
  export default {
    name: "ThemeFive",
    data() {
      return {
          ListArr: [],
          num:0
      }
    },
    props: {
        // 滚动数组
        ItemDate: {
            type: Object
        },
    },
     computed: {
    ...mapState({
      isqualification: (state) => state.isqualification,
    }),
  },
    created() {
       console.log('ThemeFive',this.ItemDate)
       this.ListArr=this.ItemDate.pictureAndLinkVOS
    },
    methods: {
       ...mapMutations({
      setCarGoodsNum: "setCarGoodsNum",
    }),
      // 点击跳转详情页   js跳转app
      jumpDetail(id, isRecentExpiration) {
         this.$router.push({ name: "ProductDetail", query: { id: id ,isRecentExpiration:isRecentExpiration } });
      },
      // 点击加入购物车
      // 点击加入购物车
      addCartOneFun(data) {
        if(data.price==="***"){
          this.$message({
              message:'请点击顶部右侧【手机商城】，扫码二维码下载商城App，上传认证信息',
              type:"warning"
          })
          return false
      }
      
      console.log('datasss',data)
                    let memberStoreId=localStorage.getItem("memberStoreId")
                      const addcartDate = [];
                      addcartDate.push({
                        isRecentExpiration:data.isRecentExpiration,
                        quantity: data.minOrderNum,
                        productId:data.id==-1?data.productId:data.id,
                        goodNum:data.goodNum,
                        memberStoreId:memberStoreId
                        // memberStoreId:this.StoreList[0].id
                      });

                      this.num=data.minOrderNum
                      // 加入购物车接口
                      AddCart(addcartDate).then((data) => {
                        // this.$router.push({ name: "Cart", query: { id: id } });
                        if (data.data.code == 200) {
                          // 提示信息
                          this.$notify({
                            title: "加入购物车",
                            message: "成功",
                            type: "success",
                            duration: 1000,
                          });
                          this.setCarGoodsNum(this.num);
                        } else {
                          this.$notify({
                            title: "提示信息",
                            message: data.data.msg,
                            type: "error",
                            duration: 1000,
                          });
                        }
                      })

       
      },
    },
     //   注册方法
    mounted() {

    }
  }
</script>
<style lang="scss" scoped>
.clear{
  clear: both;
}
.ThemeFive {
    width:100%;
    display: block;
    height: auto;
    .mainBox{
      padding:0.08rem;
        .wrapbox {
          padding:10px;
          // width: calc(50% - 30px);
          box-sizing: content-box;
          background: #fff;
         border-radius:10px;
          margin-bottom: 10px;
          &:nth-child(odd) {
             float: left;
            width: calc(50% - 35px);
            margin-right: 5px;
            margin-left:10px;
          }
          &:nth-child(even) {
            float: right;
            width: calc(50% - 35px);
            margin-right: 10px;
            margin-left:5px;
          }
          .conBox{
            width:100%;
            height: 100%;
            box-sizing: content-box;
            background-repeat: no-repeat;
            background-size: 100% 100%;
              .top {
                margin: 0 auto;
                // width:calc(100% - 0.2rem);
                //   padding: 0.08rem  0.1rem;
                width: 100%;
                padding:10px 0;
                height:auto;
                img {
                  width: 100%;
                  height: auto;
                   display: inherit;
                   cursor: pointer;

                }
              }
               .name {
                  text-indent:10px;
                padding:10px 0;
                color: #333;
                font-size: 16px;
                line-height:30px;
                font-weight: bold;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                text-align: left;
              }
                .special {
                  text-indent:10px;
                  color:  #525252;
                  font-size:13px;
                  line-height: 20px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  text-align: left;
                }

                .bottom {
                  margin-top: 10px;
                   padding-bottom:10px;
                  width: 100%;
                  height:30px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                }

                .price {
                   text-indent:10px;
                  color: #f43c38;
                  font-size:16px;
                  font-weight: bold;
                  line-height: 26px;
                }
                 .buy {
                  background: #FFD303;
                  color: #000;
                  font-size:14px;
                  border-radius:10px;
                  margin-right:10px;
                  height: 20px;
                  line-height: 20px;
                  padding:5px 10px;
                  
                }
          }
        }
    }
  }
</style>